.ordersByManagersTable .v tr {
    display: flex;
    flex-direction: column;
}

.ordersByManagersTable table {
    width: 100%;
}

.ordersByManagersTable td {
    height: 40px;
    white-space: wrap;
}

.ordersByManagersTable tr:not(:last-child) {
    border-bottom: 1px solid;
}

.ordersByManagersTable th {
    border: 1px solid;
}